export const CREDOLAB_SDK = {
  id: '349726',
  name: 'Credolab SDK',
  variants: ['50258', '50259'],
  control: '50258',
  treatment: '50259',
};

export const BELINDA_MGM_XP_ID = '349770';
export const BELINDA_MGM_XP_VARIANT_ID = '50318';
